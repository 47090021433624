.pricingButton{
    display: flex;
    justify-content: center;
    height: 80px;
    width: 100%;
    margin-top:20%;
}

.pricingButtonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 24px !important;
    align-self: flex-end;
}


.pricingCard {
    border: 1px solid #E1E6ED;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 500px;
    height:950px;
    padding: 40px 56px;
    position: relative;
    overflow: hidden;
}


.cardLine
{
    width: 100%;
    background-color: lightgrey;
    border:none; 
    border-bottom: 1px solid #E1E6ED;
}

.cardLine2
{
    width: 100%;
    background-color: lightgrey;
    border:none; 
    border-bottom: 1px solid #E1E6ED;
    margin-top: 5px;
}


.cardText
{
    color:#797B82;
    font-size: 24px;
    height: 300px;
}


.cardTopOrangeLine
{
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: #F37021;
    top: 0px;
}


.cardsCont
{
    max-width: 1200px;
    display: flex;
    margin: auto;
    column-gap: 48px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 30px;
}

.pricingCardHeader
{
    /* font-family: Noto Sans CJK KR; */
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    font-weight:700;
    margin-bottom:5% !important;
    /* identical to box height, or 120% */
    color: #F37021;
    margin: 0px;
}

.pricingCardList
{   
    height: 250px;
}

.pricingCardList2 {
    height: 275px;
}

.pricingCardList li
{
    text-align: start;
    font-family: Noto Sans CJK KR;
    font-style: normal;
    font-weight: normal;
    font-size: 24px !important;
    line-height: 150% !important;
    align-items: center;
    color: #6B7480;
}

.eduApiSectionCard
{
   display: flex;
   flex-direction: column;
   background-color: white;
   border: 1px solid #E1E6ED;
box-sizing: border-box;
border-radius: 10px;
padding: 30px 56px;
height:500px;
}

.eduApiCardHeader
{
    font-size: 24px;
    color: #6B7480;
    text-align: center;
    margin: 0px;
}


.eduApiCardLine
{
    width: 100%;
    border: none;
     border-bottom: 1px solid #E1E6ED;
     margin: 0px;
     margin-top: 40px;
}
.eduApiSectionList
{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px;
    padding: 0px;
    margin-top: 36px;
}


.eduApiSectionList li
{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    column-gap: 8px;
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 24px;
/* identical to box height, or 160% */

display: flex;
align-items: center;

/* text/grey2 */

color: #494B52;

}

@media screen and (max-width: 768px) {
    .pricingCard {
        width: 375px;
        height: auto;
        padding: 20px;
    }
    .cardsCont
    {
        padding: 20px;
    }
    .pricingCardList2 {
        height: 80px;
    }
    .cardText {
        margin-top: 20px;
    }
}