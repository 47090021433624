.mainTopHeader {
    font-size: 2.625em;
    width: 100%;
}

.mainTopContent  {
    font-size: 1.5em;
}

.mobileMainTextWidth {
    font-size: 42px;
    font-weight: 700;
}

.mobileSubTextWidth {
    font-size:1.750em;
    font-weight: 700;
}

h1 {
    font-size:2.625em !important;
}

h3 {
    font-size:1.750em !important;
}

.changeTextWidth {
    font-size: 24px;
    color:#494B52;
}

.mobileLinkMainWatchVideo {
    font-size: 24px;
}

.next-level-h3 {
    font-size:42px !important;
}