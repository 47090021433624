.pageTopPartEducationSolution
{   
    height: 400px;
    position:relative; 
    width:1080px;
    overflow:visible; 
    display:flex;
}

.pageTopPartTextPartEducationSolution
{   
    height: 250px;
    position:relative; 
    width:1080px;
    overflow:visible; 
    display:flex;
    margin-top: 48px;
}

.pageTopParth3
{   
    font-size: 15px;
    margin: 0px ;
    padding: 0px ;
}


.pageTopParth1
{   
    font-size: 48px !important;
    margin: 0px ;
    padding: 0px ;
    margin-top: 8px;
    line-height: 64px;  
}

.pageTopPartButton
{   
    width: 180px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background: #F37021;
    border-radius: 4px;
    border: none;
    color:white;
    margin-top: 80px;
}
.solutionPageTopImage
{
    position: absolute;
}

.solution-divider
{
    height: 180px;
}


@media screen and (max-width:1200px){
    .pageTopPartEducationSolution
    {   
        flex-direction: column;
        height: auto;
        width:auto;
        margin: auto;
        align-items: center;
        height: auto;
        /* row-gap: 20px; */
    }
    
    .pageTopPartTextPartEducationSolution
    {   
       flex-direction: column;
       align-items: center;
       text-align: center;
        width:auto;
        height: auto;

    }
    .pageTopPartButton
    {   
        width:460px;
        height:56px;
        margin-top: 40px;
        align-self: center;
    }
    .solutionPageTopImage
    {
        position: relative;
        width: 688px;
    }
    .pageTopParth3
    {   
    }
    .pageTopParth1
    {   
        font-size: 28px;
        line-height: 48px;
        margin-top: 8px !important;
    }

    .solution-divider
    {
        height: 80px;
    }
}